<template>
  <div class="agreement_template">
    <template v-if="!getLoadings.assigning && listing">
      <div class="card mb-3">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="fw-bold d-flex align-items-center">
              Huurovereenkomst {{ listing.title }}
            </div>
            <div>
              <button class="btn btn-primary btn-sm" @click="downloadAgreement()">
                Download
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 p-0 bg-white leftContent">
          <div
            class="border-bottom py-2 px-4 fs-14px fw-bold"
            v-for="(heading, key) in headings"
            :key="key"
          >
            <div v-html="heading"></div>
          </div>
        </div>
        <div class="col-md-9 rightContent">
          <div class="card">
            <div ref="agreement_template" v-html="agreement_template"></div>
          </div>
        </div>
      </div>
    </template>
    <Spinner v-else />
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ListingAssigned",
  components: {
    Spinner,
  },
  data() {
    return {
      selectedRowId: null,
      tenant: null,
      agreement_template: "",
      headings: [],
    };
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["assignings", "listing", "user"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    listingId() {
      return this.$route.params.listingId;
    },
  },
  methods: {
    ...mapActions("requestsAndAppointment", [
      "cancelAssigning",
      "getAssignings",
      "getListingAgreementTemplate",
      "saveAgreementData",
    ]),
    showDetails(tenant) {
      this.tenant = tenant;
      // this.$bvModal.show("tenant-modal");
      this.$router.push({
        name: "ListingAssignedTenantDetail",
        params: {
          listingId: this.listingId,
          tenant_id: tenant.id,
        },
      });
    },
    change(e, update = true) {
      // console.log("key", e, e.target, e.target, e.name);

      let key = e.target.name;
      let value = e.target.value;

      if (e.target.checked == false && e.target.type == "checkbox") {
        value = null;
        if (key == "guarantor") {
          document.getElementById(`${e.target.id}-content`).style.display = "none";
        }
      }

      if (e.target.type == "checkbox") {
        try {
          if (e.target.checked) {
            document.getElementById(`${e.target.id}-content`).style.display = "block";
          } else {
            document.getElementById(`${e.target.id}-content`).style.display = "none";
          }
        } catch (error) {
          console.log("Error", error);
        }
      }

      if (e.target.checked && e.target.type == "radio") {
        for (let el of document.querySelectorAll(`.${e.target.name}`))
          el.style.display = "none";
        document.getElementById(`${e.target.id}-content`).style.display = "block";
      }

      if (["listing_price", "deposit_glw", "deposit_other"].includes(e.target.name)) {
        let listing_price = document.getElementById("listing_price").value;
        let deposit_glw = document.getElementById("deposit_glw").value;
        let deposit_other = document.getElementById("deposit_other").value;

        listing_price = listing_price.replace("€", "");
        deposit_glw = deposit_glw.replace("€", "");
        deposit_other = deposit_other.replace("€", "");

        document.getElementById("total_rent").value =
          Number(listing_price) + Number(deposit_glw) + Number(deposit_other);
        document.getElementById("total_rent").dispatchEvent(new Event("change"));
      }

      if (update) {
        if (e.target.type == "file") {
          let formData = new FormData();
          let listing_id = this.$route.params.listingId;

          formData.append("file", e.target.files[0]);
          formData.append("key", key);
          window.axios
            .post(`/broker/listings/${listing_id}/agreement`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              console.log("response", response.data.key);
              console.log("SUCCESS!!", `${e.target.id}`);
              document.getElementById(e.target.id).innerHTML = response.data.key;
              document.getElementById(e.target.id).href = response.data.key;
            })
            .catch(function (error) {
              console.log("FAILURE!!", error);
            });
        } else {
          this.saveAgreementData({
            listing_id: this.$route.params.listingId,
            payload: { key: key, value: value },
          });
        }

        this.$refs.agreement_template.querySelectorAll("input").forEach((input) => {
          if (input.name == key && input.type != "file") {
            input.value = value;
            // input.dispatchEvent(new Event("change"));
          }
        });
      }
    },
    downloadAgreement() {
      return window
        .axios({
          url: `/broker/listings/${this.listingId}/agreement`,
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          const href = window.URL.createObjectURL(response.data);

          const anchorElement = document.createElement("a");

          anchorElement.href = href;
          anchorElement.download = `agreement_${this.listingId}.pdf`;

          document.body.appendChild(anchorElement);
          anchorElement.click();

          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
  },
  created() {
    console.log("assigned created", this.listingId, this.listing?.id);
    if (this.listingId != this.listing?.id || !this.listing?.assigned?.user) {
      this.getAssignings({ listingId: this.listingId });
    }
    this.getListingAgreementTemplate(this.$route.params.listingId).then((response) => {
      this.agreement_template = response.listing_agreement_template;
      this.headings = response.headings;
      setTimeout(() => {
        for (let el of document.querySelectorAll(".options")) el.style.display = "none";
        this.$refs.agreement_template.querySelectorAll("select").forEach((input) => {
          input.addEventListener("change", this.change);
          this.change({ target: input }, false);
        });

        this.$refs.agreement_template.querySelectorAll("input").forEach((input) => {
          input.addEventListener("change", this.change);
          this.change({ target: input }, false);
          // if (
          //   (input.checked && input.type == "radio") ||
          //   (input.checked && input.id == "guarantor")
          // ) {
          //   console.log(
          //     "document.getElementById(`${input.id}-content`)",
          //     document.getElementById(`${input.id}-content`),
          //     `${input.id}-content`
          //   );
          //   document.getElementById(`${input.id}-content`).style.display = "block";
          // }
        });
      }, 1000);
    });
  },
};
</script>
<style lang="scss">
.agreement_template {
  input {
    padding: 5px;
    border: 1px solid $gray4;
    border-radius: 5px;
  }

  .rightContent {
    height: 77vh;
    overflow-y: scroll;
    padding: 5rem !important;

    .card {
      border-radius: 0px;
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.149);
      border: 0px;
      padding: 5rem !important;
    }
  }

  .leftContent {
    height: 77vh;
    overflow-y: scroll;
  }

  select {
    -webkit-appearance: auto !important;
    border-style: solid !important;
    border-radius: 5px;
    padding: 5px;
  }
}
</style>
